/* eslint-disable no-unused-vars */
import React, { useState,useEffect  } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { useRideAutomatorService } from "../../services/ride-automator-api.hook";
import Cookies from 'js-cookie';
function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [credentials, setCredentials] = useState({
    user_name: "",
    password: "",
  });
  const [fullname, setFullname] = useState("");
  const { postProxy } = useRideAutomatorService();

  useEffect(() => {
    // Get the geolocation coordinates
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCredentials({
            ...credentials,latitude:position.coords.latitude,longitude:position.coords.longitude
          })
      
        },
        (error) => {
          console.log('Error getting geolocation:', error);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }, [navigator.geolocation]);


  const handleLogin = (event) => {
    event.preventDefault();

    if (!credentials.user_name) {
      setError("Username is required");
      return;
    }
    if (!credentials.password) {
      setError("Password is required");
      return;
    }
    postProxy("api/login", credentials)
      .then((res) => {
        const { user_id, full_name,user_name} = res.data;
        Cookies.set('userId', user_id);
        Cookies.set('full_name', full_name);      
        setFullname(full_name);         
        navigate("/app/home", { state: { fullname: full_name,user_id:user_id,user_name:user_name,} });     
      })
      .catch((error) => {
        console.log("Error from server:", error);
        if (error.response && error.response.status === 401) {
          setError("Invalid credentials. Please try again.");
        } else {
          setError("An error occurred. Please try again later.");
        }
      });

  };

  return (
    <div>
      <div className="navBar">
        <header className="containerr">
          <h2>RIDE AUTOMATOR</h2>
        </header>
      </div>
      <div className="container">
      <div className="text"> <p className="logintext">LOGIN</p>
     
        
        <form onSubmit={handleLogin}>
          <div className="fields">
            <input
              type="text"
              className="textInput"
              value={credentials.user_name}
              onChange={(event) =>
                setCredentials({ ...credentials, user_name: event.target.value })
              }
              placeholder="Username"
            />
            {error && !credentials.user_name && (
    <div className="error">Username is required</div>
  )}
          </div>

          <div className="fields">
            <input
              type="password"
              className="textInput"
              value={credentials.password}
              onChange={(event) =>
                setCredentials({ ...credentials, password: event.target.value })
              }
              placeholder="Password"
            />
           {error && !credentials.password && (
              <div className="error">Password is required</div>
            )}

            {error && credentials.password && (
              <div className="error">Invalid credentials. Please try again.</div>
            )}
          </div>
<div className="logbutton">
          <button type="submit" className="loginButton">
            LOGIN
          </button></div>
        </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
