/* eslint-disable no-unused-vars */
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const LogoutModalComponent = ({ show, handleClose, handleConfirmLogout }) => {
  const handleYesClick = () => {
    handleConfirmLogout(); 
  };

  const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    fontFamily:"sans-serif"
  };

  const modalContentStyle = {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "4px",
    height: "150px",
    width: "300px",
  };

  const footerStyle = {
    // marginTop: "30px",
    display: "flex",
    justifyContent: "flex-end",
  };

  const headerStyle = {
    // display: "flex",
    justifyContent: "flex-start",
    marginBottom: "10px",
  };

  const titleStyle = {
    marginLeft: "0",
  };



  return (
    <Modal show={show} onHide={handleClose}  centered>
    <div style={modalStyle}>
    <div style={modalContentStyle}>
    <div style={headerStyle}>
            <h3 style={titleStyle}>Logout Confirmation </h3>
          </div>
      <Modal.Body><p>Scripts execution will be stopped if it is running while logging out. Are you sure want to logout?
</p></Modal.Body>
      <Modal.Footer>
      <div style={footerStyle}>
      <button onClick={handleYesClick} style={{height:"25px", width:"45px", backgroundColor:"black", color:"white", fontFamily:"sans-serif"}}>YES</button>
        <button onClick={handleClose} style={{height:"25px", width:"45px", backgroundColor:"black", color:"white", fontFamily:"sans-serif", marginLeft:"20px"}}>NO</button>
        </div>
      </Modal.Footer>
      </div>
      </div>
    </Modal>
  );
};

export  {LogoutModalComponent};

