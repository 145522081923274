import React from 'react'
import Cookies from "js-cookie";
import { Navigate } from 'react-router-dom';
const ProtectedRoute = ({children}) => {
    let userId = Cookies.get("userId");

    if (!userId) {
      return <Navigate to="/app/login" />;
    }

    return children;
}

export default ProtectedRoute