import axios from 'axios';
import React from 'react';

export const useRideAutomatorService = () => {
  const API_URL = process.env.REACT_APP_API;
  const _axios = axios.create();
  const postProxy = React.useCallback(
    (url, data) => {
      return _axios
        .post(`${API_URL}/${url}`, data)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response ? err.response.data : err;
        });
    },
    [_axios]
  );

  const putProxy = React.useCallback(
    (url, data) => {
      return _axios
        .put(`${API_URL}/${url}`, data)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response ? err.response.data : err;
        });
    },
    [_axios]
  );

  const getProxy = React.useCallback(
    (url) => {
      return _axios
        .get(`${API_URL}/${url}`)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    [_axios]
  );

  const deleteProxy = React.useCallback(
    (url, data) => {
      return _axios
        .delete(`${API_URL}/${url}`)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response ? err.response.data : err;
        });
    },
    [_axios]
  );

  return { postProxy, getProxy, putProxy, deleteProxy };
};