/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useRef, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "./home.css";
import { useLocation } from "react-router-dom";
import { useRideAutomatorService } from "../../services/ride-automator-api.hook";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { LogoutModalComponent } from "../../components/modals/logOutpop/logOutpop";
import Form from "react-bootstrap/Form";
function Home(props) {
  // const navigate = useNavigate();
  const location = useLocation();
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  // const [grid, setGrid] = useState(null);
  const [status, setStatus] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [statusColorMsg, setStatusColorMsg] = useState("");
  const [statusColor, setStatusColor] = useState("");
  const fullname_data = location.state ? location.state.fullname : "";
  const fullName =
    fullname_data.charAt(0).toUpperCase() + fullname_data.slice(1);
  const userId = location.state.user_id;
  const userName = location.state.user_name;
  const { getProxy, postProxy, putProxy } = useRideAutomatorService();
  // const [isDisabledtable, setIsDisabledTable] = useState(0);
  const user_id = userId;
  const [showModal, setShowModal] = useState(false);
  const [newData, setnewData] = useState([]);
  const [newDataRemoved, setNewDataRemoved] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  //logout button click opens modal
  const handleButtonClick = () => {
    setShowModal(true);
  };
  //modal close
  const handleCloseModal = () => {
    setShowModal(false);
  };

  //modal YES click logouts
  const handleConfirmLogout = () => {
    if (ButtonDisabled) {
      handleClickStop();
    }
    setShowModal(false);
    const data = { user_name: userName };
    postProxy("api/logout", data)
      .then((res) => {
        res.json("logout");
      })
      .catch((error) => {
        console.log("Error from server:", error);
      });
    document.cookie.split(";").forEach((cookie) => {
      document.cookie = cookie
        .replace(/^ +/, "")
        .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
    });
    // Clear local storage
    localStorage.clear();
    window.location.href = "/";
  };

  // const [rowchange, setRowchange] = useState(false);
  const [rowData, setRowData] = useState([]);
  const columnDefs = [
    {
      headerName: "ID",
      field: "ride_id",
      filter: true,
      checkboxSelection: true,
      minWidth: 130,
      suppressSizeToFit: true,
      cellRendererParams: {
        checkbox: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checked: true,
      },
    },
    { headerName: "Ride Name", field: "ride_name", minWidth: 300 },
    { headerName: "Preffered", field: "is_prioritize", minWidth: 110 },
    { headerName: "Description", field: "description", minWidth: 250 },
  ];

  const handleCustomCheckbox = (data) => {
    const tempdata = [];
    data.is_prioritize = false;
    tempdata.push(data);
    setNewDataRemoved(true);
    setSelectedData(tempdata);
  };

  const columnDefsNew = [
    {
      headerName: "ID",
      cellRendererParams: {
        valueGetter: (params) => params.data.is_prioritize,
      },
      field: "ride_id",
      filter: true,
      rowDrag: true,
      minWidth: 150,
      cellRenderer: (params) => {
        if (params.node) {
          return (
            <div>
              <Form>
                <Form.Check
                  type={"checkbox"}
                  checked={true}
                  className="customCheckbox"
                  label={params.data.ride_id}
                  onChange={() => handleCustomCheckbox(params.data)}
                />
              </Form>
            </div>
          );
        }
      },
    },
    { headerName: "Ride Name", field: "ride_name", minWidth: 300 },
    { headerName: "Ride Order", field: "ride_order", minWidth: 110 },
    { headerName: "Preffered", field: "is_prioritize", minWidth: 110 },
    { headerName: "Description", field: "description", minWidth: 250 },
  ];

  const [ButtonDisabled, setButtonDisabled] = useState(0);

  const handleStatusChange = (condition) => {
    let message = "";
    let color = "";

    // Set the current Status based on the condition
    if (condition === null) {
      message = "Not Found";
      color = "Orange";
    } else if (condition === 1) {
      message = "Running...";
      color = "DeepSkyBlue";
    } else if (condition === 2) {
      message = "Completed";
      color = "green";
    } else if (condition === 3) {
      message = "Error";
      color = "red";
    } else if (condition === 4) {
      message = "Force Stop";
      color = "LightCoral";
    }
    setStatusColorMsg(color);
    setStatus(message);
    setStatusColor(color);
  };

  const refreshData = () => {
    const timeInterval = process.env.REACT_APP_TIME;
    const interval = setInterval(() => {
      getProxy(`api/users/${user_id}`)
        .then((response) => {
          const { last_ride_status, ride_status_message, ride_status } =
            response.data;
          const status = response.data.ride_status;
          if (Object.keys(response.data).length) {
            if (response.data.ride_status === 0) {
              clearInterval(interval);
            } else {
              // showTostMessage("fail", "fail");
            }
          }
          setStatusMessage(ride_status_message);
          handleStatusChange(last_ride_status || ride_status);
          setButtonDisabled(status);
          // setIsDisabledTable(status);
        })
        .catch((error) => {
          clearInterval(interval);
          console.log("error", error);
        });
    }, timeInterval);
  };

  const handleClickRun = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString([], {  hour12: false });
    const last_run_time = `${formattedDate} ${formattedTime}`;
    // const user_id = userId;
    const dataToSend = { user_id:userId,last_run_time };
    postProxy("api/ride/run", dataToSend)
      .then((res) => {
        refreshData();
        // handleStatusChange(1);
        setButtonDisabled(1);
        // setIsDisabledTable(1);
      })
      .catch((error) => {
        console.log("Error from server:", error);
      });
  };

  const gridOptions = {
    columnDefs: columnDefsNew,
    rowData: newData,
  };

  const handleClickStop = () => {
    const user_id = userId;
    const dataToSend = { user_id };
    postProxy("api/ride/stop", dataToSend)
      .then((res) => {
        // setTableDisabled(false);
        getUserDetails();
      })
      .catch((error) => {
        console.log("Error from server:", error);
      });
    setButtonDisabled(0);
    // setIsDisabledTable(0);
  };

  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    rowSelection: "multiple",
    flex: 1,
    minWidth: 100,
  }));

  const getRideDetails = () => {
    getProxy("api/ride")
      .then((response) => {
        const filteredRowData = response.data.filter(
          (item) => !item.is_prioritize
        );
        // setRowData(response.data.filter((item) => !item.is_prioritize))
        setnewData(response.data.filter((item) => item.is_prioritize));
        const sortedArray = filteredRowData.sort((a, b) => {
          const rideNameA = a.ride_name.toUpperCase();
          const rideNameB = b.ride_name.toUpperCase();
          if (rideNameA < rideNameB) {
            return -1;
          }
          if (rideNameA > rideNameB) {
            return 1;
          }
          return 0;
        });
        setRowData(sortedArray);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getUserDetails = () => {
    getProxy(`api/users/${user_id}`)
      .then((response) => {
        const { last_ride_status, ride_status_message, ride_status } =
          response.data;
        setStatusMessage(ride_status_message);
        handleStatusChange(last_ride_status || ride_status);
        setButtonDisabled(ride_status);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const updateRideDetails = (data) => {
    const dataToSend = { data: data };
    putProxy("api/ride", dataToSend)
      .then((res) => {
        // setRowData(rowData);
        setSelectedData([]);
        setNewDataRemoved(false);
        getRideDetails();
        if (gridApi) {
          gridApi.setRowData(rowData);
        }
      })
      .catch((error) => {
        console.log("Error from server:", error);
      });
  };

  useEffect(() => {
    getRideDetails();
    getUserDetails();
  }, []);

  //oncheckbox click data changes
  useEffect(() => {
    getRideDetails();
  }, [JSON.stringify(rowData)]);

  const onSelectionChanged = (params) => {
    // setRowchange(true);
    let selectedRowData = params.api.getSelectedRows();
    let getData = [...newData];
    if (selectedRowData.length) {
      let selectedObject = { ...selectedRowData[0], is_prioritize: true };
      getData.unshift(selectedObject);
      setnewData(getData);
      let oldData = rowData.filter(
        (obj) => obj.ride_id !== selectedObject.ride_id
      );
      if (gridApi) {
        gridApi.refreshCells();
      }
      setRowData(oldData);
    }
    getData.forEach((data, index) => {
      data.ride_order = index + 1;
    });
    setnewData(getData);
    updateRideDetails(getData);
  };

  const onRowDragEnd = (event) => {
    const { overIndex, node } = event;
    // setRowchange(true);
    // const dragRowData = event.overNode.data;
    // const newIndex = overIndex + 1;
    const rowData = node.data;
    const indexToMove = node.rowIndex;
    const removed = newData.splice(newData.indexOf(rowData), 1)[0];
    newData.splice(indexToMove, 0, removed);
    newData.forEach((data, index) => {
      data.ride_order = index + 1;
    });
    setnewData(newData);
    updateRideDetails(newData);
  };

  const onGridReady = (params) => {
    // const gridApi = params.api;
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
    params.api.selectAll(true);
  };

  useEffect(() => {
    if (selectedData.length) {
      let updatedArray = newData.filter(
        (obj) => obj.ride_id !== selectedData[0].ride_id
      );
      updatedArray.push(selectedData[0]);
      updatedArray.forEach((data, index) => {
        if (data.ride_id !== selectedData[0].ride_id) {
          data.ride_order = index + 1;
        }
      });
      updateRideDetails(updatedArray);
    }
  }, [newDataRemoved]);

  return (
    <div>
      <div>
        <div className="navBar">
          <header className="containerr">
            <h2>RIDE AUTOMATOR </h2>
            <div className="divContainer">
              <span>Welcome! {fullName}</span>
              <button
                className="logoutButton"
                onClick={handleButtonClick}
                style={{ marginLeft: 20 }}
              >
                LOG OUT
              </button>
            </div>
          </header>
          <LogoutModalComponent
            show={showModal}
            handleClose={handleCloseModal}
            handleConfirmLogout={() => handleConfirmLogout()}
          />
        </div>
        <div className="status">
          <h4>
            {" "}
            Last Run Status:{" "}
            <span style={{ color: statusColor }}> {status}</span>
          </h4>
          <h4 style={{ marginLeft: "2em" }}>
            {" "}
            Last Run Message:{" "}
            <span style={{ color: statusColorMsg }}> {statusMessage}</span>
          </h4>
        </div>

        <div style={{ position: "relative", margin: "30px" }}>
          <div
            className={`ag-theme-alpine ${ButtonDisabled ? "disabled" : ""}`}
            style={{ height: "50vh", width: "auto", overflow: "auto" }}
          >
            <AgGridReact
              rowDragManaged={true} //managed row dragging is not possible when doing pagination
              rowDragEntireRow={true}
              rowDragMultiRow={true}
              rowData={newData}
              columnDefs={columnDefsNew}
              suppressRowClickSelection={true}
              rowSelection={true}
              defaultColDef={defaultColDef}
              suppressMoveWhenRowDragging={true}
              onRowDragEnd={onRowDragEnd}
              onGridReady={onGridReady}
              ref={gridRef}
            />
          </div>
        </div>

        <div style={{ position: "relative", margin: "30px" }}>
          <div
            className={`ag-theme-alpine ${ButtonDisabled ? "disabled" : ""}`}
            style={{ height: "60vh", width: "auto", overflow: "auto" }}
          >
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              suppressMoveWhenRowDragging={true}
              onSelectionChanged={onSelectionChanged}
              onGridReady={onGridReady}
              gridOptions={gridOptions}
            />
          </div>
        </div>
        <div className="navBar2">
          <footer>
            <div className="footerButton">
              <button
                className="run"
                disabled={ButtonDisabled === 1}
                onClick={handleClickRun}
              >
                RUN
              </button>
              <button
                className="stop"
                disabled={ButtonDisabled === 0}
                onClick={handleClickStop}
                style={{ marginLeft: 20 }}
              >
                STOP
              </button>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Home;
