/* eslint-disable no-unused-vars */
import React, { Children } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/login/login";
import Home from "./pages/home/home";
import NotFound from "./pages/NotFound/notfound";
import ProtectedRoute from "./components/protectedRoute";
function App() {
  
  return (
    <div className="App">
      <Routes>
        {/* <Route path="/" element={<Navigate to="app/login" />} /> */}
        <Route
          path="app/login"
          element={ <Login />}
        />
        <Route path="app/home" element={ <ProtectedRoute>  <Home /></ProtectedRoute>}/>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;